.noti-icon .badge {
  left: 23px;
}
.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 100px;
}
.fcYNFc {
  background-color: #ffffff !important;
}
.task-box {
  border: 1px solid #e1e1e6;
}
.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}
.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

button.add-menu {
  display: block;
  margin: 0;
  margin-left: auto;
  margin-bottom: 10px;
}
.custom-file-input {
  z-index: 0 !important;
}
.custom-file-label {
  z-index: 0 !important;
}

.form .checkbox {
  width: 50%;
  display: inline-block;
  padding: 0 20px;
}

.addressGroup {
  border: 1px solid #ddd;
  padding: 20px;
}

.address-label {
  font-size: "14px";
  font-weight: 700;
}

.dropdown-suborder {
  float: right;
  margin-right: 5px;
}

.not-visible {
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
  opacity: 0;
  height: 0;
  // overflow: hidden;
  td {
    transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0s;
    height: 0px !important;
    display: table-column;
    border: none;
  }
}

.visible {
  border: none;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.74, 0.05) 0s;
  opacity: 1 !important;
  height: 25px !important;
  td {
    transition: all 0.3s cubic-bezier(0.6, -0.28, 0.74, 0.05) 0s;
    height: 25px !important;
  }
}

//slider
.rc-slider {
  height: 40px;
}

.rc-slider-rail {
  height: 10px;
}

.rc-slider-track {
  height: 10px;
}

.rc-slider-step {
  height: 10px;
}

.rc-slider-handle {
  width: 10px;
  height: 10px;
  margin-top: 0px;
  background-color: #00afff;
  border: solid 3px #00afff;
}

.rc-slider-mark {
  top: 15px;
  margin-bottom: 0px;
}

.rc-slider-dot {
  bottom: -5px;
  width: 20px;
  height: 20px;
  border: 2px solid rgb(209 209 209);
}

.rc-slider-dot:nth-child(1) {
  background-color: rgb(125, 214, 255);
}
.rc-slider-dot:nth-child(2) {
  background-color: #ffdb3a;
}
.rc-slider-dot:nth-child(3) {
  background-color: rgb(255, 166, 50);
}
.rc-slider-dot:nth-child(4) {
  background-color: rgb(255, 57, 57);
}

.rc-slider-dot-active {
  border-color: #abe2fb;
}
